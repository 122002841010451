<template>
  <div style="float:left;width:100%">
    <Refresh :reType="type"></Refresh>
    <van-row
      class="main"
      style="border:1px solid #d9d4d4;height:3rem;border-left:0px;border-right:0px;background-color:#fff"
    >
      <van-col span="14" class="head">
        <van-cell @click="showPopup" style="width:100%">{{
          timer(timeDate)
        }}</van-cell>
      </van-col>
      <van-col class="text" span="5">
        <van-button type="success" size="small" @click="frontDate"
          >上个月</van-button
        >
      </van-col>
      <van-col class="text" span="5">
        <van-button type="success" size="small" @click="behindDate"
          >下个月</van-button
        >
      </van-col>
    </van-row>
    <div class="messagePrompt" v-if="!list.length > 0 && httpType == false">
      <p style="text-align:center">
        <van-icon name="warning" size="7rem" color="rgb(9 131 236)" />
      </p>
      <p style="text-align:center;font-size:1.3rem">
        系统提示：
      </p>
      <p style="text-align:center;font-size:0.8rem;color:rgb(123 132 141)">
        未找到你想要的数据！
      </p>
    </div>
    <div class="messagePrompt" v-if="httpType">
      <p style="text-align:center">
        <van-icon name="clear" size="7rem" color="#ff4d4f" />
      </p>
      <p style="text-align:center;font-size:1.3rem">
        系统提示：
      </p>
      <p style="text-align:center;font-size:0.8rem;color:rgb(123 132 141)">
        网络异常，请稍后再试！
      </p>
    </div>
    <div
      class="recordDetailsBottom"
      style="margin-top:15px"
      v-if="list.length > 0"
    >
      <van-row>
        <van-col span="24">
          <van-row style="height:2rem;width:93%;margin:auto;line-height: 2rem;">
            <van-col
              span="4"
              style="text-align:center;font-size:0.8rem;color:rgb(158 157 157)"
              >合 计 量</van-col
            >
            <van-col span="20" class="headText">{{ Total }}件</van-col>
          </van-row>
        </van-col>
        <van-col span="24">
          <van-row style="height:2rem;width:93%;margin:auto;line-height: 2rem;">
            <van-col
              span="4"
              style="text-align:center;font-size:0.8rem;color:rgb(158 157 157)"
              >合计金额</van-col
            >
            <van-col span="20" class="headText">￥{{ TotalAmount }}</van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <div class="recordDetails" :key="index" v-for="(item, index) in list">
      <div class="recordDetailsTop">
        <van-row>
          <van-col span="24">
            <van-row
              style="height:2rem;width:93%;margin:auto;line-height: 2rem;"
            >
              <van-col
                span="4"
                style="text-align:center;font-size:0.8rem;color:rgb(158 157 157)"
                >计薪日期</van-col
              >
              <van-col span="20" class="headText">{{ item.workeDate }}</van-col>
            </van-row>
          </van-col>
          <van-col span="24">
            <van-row
              style="height:2rem;width:93%;margin:auto;line-height: 2rem;"
            >
              <van-col
                span="4"
                style="text-align:center;font-size:0.8rem;color:rgb(158 157 157)"
              >
                工 作 量</van-col
              >
              <van-col span="20" class="headText"
                >{{ item.quantity }}件</van-col
              >
            </van-row>
          </van-col>
          <van-col span="24">
            <van-row
              style="height:2rem;width:93%;margin:auto;line-height: 2rem;"
            >
              <van-col
                span="4"
                style="text-align:center;font-size:0.8rem;color:rgb(158 157 157)"
                >薪资金额</van-col
              >
              <van-col span="20" class="headText"
                >￥{{ Number(item.amount).toFixed(2) }}</van-col
              >
            </van-row>
          </van-col>
        </van-row>
      </div>
    </div>

    <div style="float:left;width:100%;height:4rem"></div>
    <van-overlay :show="show" @click="show = false">
      <div class="Popup" :style="show ? 'bottom: 0rem;' : 'bottom: -19rem;'">
        <van-datetime-picker
          v-model="currentDate"
          type="year-month"
          title="选择年月"
          @confirm="confirmTimer"
          @cancel="cancelTimer"
        />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Grid, GridItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Image as VanImage } from "vant";
import { Row, Col } from "vant";
import { Field } from "vant";
import { Button } from "vant";
import { DatetimePicker } from "vant";
import { Overlay } from "vant";
import { Icon } from "vant";
import { dosalaryStatistics } from "@/config/_api";
import Refresh from "../../refresh/index.vue";
import { GetWorkloadDisplay } from "@/config/_api";
export default {
  components: {
    [Overlay.name]: Overlay,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Button.name]: Button,
    [DatetimePicker.name]: DatetimePicker,
    Refresh,
  },
  data() {
    return {
      show: false,
      currentDate: new Date(),
      timeDate: new Date(),
      list: [],
      Total: 0,
      TotalAmount: 0,
      httpType: false,
    };
  },
  created() {
    this.fetchData();
    this.workloadDisplay();
  },
  methods: {
    async workloadDisplay() {
      let { data } = await GetWorkloadDisplay();
      this.type = data;
    },
    async fetchData() {
      this.TotalAmount = 0;
      this.Total = 0;
      let { data } = await dosalaryStatistics({
        year: new Date(this.timeDate).getFullYear(),
        month: new Date(this.timeDate).getMonth() + 1,
      });
      if (data && data.code == 1) {
        this.list = data.data;

        this.list.map((item) => {
          this.Total += item.quantity;
          this.TotalAmount += parseFloat(item.amount);
        });
        0;
        this.TotalAmount = this.TotalAmount ? this.TotalAmount.toFixed(2) : 0;
      } else {
        this.httpType = true;
      }
    },
    frontDate() {
      let timer = new Date(this.timeDate);
      var year = timer.getFullYear(); //获取当前日期的年份
      var month = timer.getMonth() + 1; //获取当前日期的月份
      var year2 = year;
      var month2 = month - 1;
      if (month2 < 1) {
        year2 = year2 - 1;
        month2 = 12;
      }
      let month3 = month2 < 10 ? "0" + month2 : month2;
      var t2 = year2 + "-" + month3;
      this.timeDate = t2;
      this.fetchData();
    },
    behindDate() {
      let timer = new Date(this.timeDate);
      var year = timer.getFullYear(); //获取当前日期的年份
      var month = timer.getMonth() + 1; //获取当前日期的月份
      var year2 = year;
      var month2 = month + 1;
      if (month2 == 13) {
        year2 = year2 + 1;
        month2 = 1;
      }
      let month3 = month2 < 10 ? "0" + month2 : month2;
      var t2 = year2 + "-" + month3;
      this.timeDate = t2;
      this.fetchData();
    },
    confirmTimer() {
      this.show = false;
      this.timeDate = this.currentDate;
      this.fetchData();
    },
    cancelTimer() {
      this.show = false;
    },
    timer(data) {
      let timer = new Date(data);
      let year = timer.getFullYear();
      let month =
        timer.getMonth() + 1 < 10
          ? "0" + (timer.getMonth() + 1)
          : timer.getMonth() + 1;
      let dataTimer = year + "年" + month + "月";
      return dataTimer;
    },
    showPopup() {
      this.show = !this.show;
    },
  },
};
</script>
<style scoped>
.text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.head {
  display: flex;
  align-items: center;
  text-indent: 10px;
}
.Popup {
  width: 100%;
  height: 19rem;
  overflow: hidden;
  position: fixed;
  /* bottom: -19rem; */
  z-index: 10;
  transition: all 0.2s linear;
}
.messagePrompt {
  width: 100%;
  height: 15rem;
}
.headText {
  text-align: right;
  font-size: 0.8rem;
}
.recordDetails {
  width: 100%;
  margin-top: 1rem;
}
.recordDetailsTop {
  width: 100%;
  border-top: 1px dashed #d9d4d4;
  border-bottom: 1px dashed #d9d4d4;
  background-color: #fff;
}
.recordDetailsBottom {
  width: 100%;
  border-top: 1px dashed #d9d4d4;
  border-bottom: 1px dashed #d9d4d4;
  background-color: #fff;
}
</style>
